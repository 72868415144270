import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { environment } from '@environment';
import { AuthData } from '../../models/auth-data.model';
import { UserRole } from '../../models/user-role.model';
import { AuthDataBE } from '../../models/auth-data-be.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly httpClient: HttpClient) {}

  login(email: string, password: string): Observable<AuthData> {
    const url = `${environment.apiEnv}api/rest-auth/login/`;
    return this.httpClient
      .post<AuthDataBE>(url, { email, password })
      .pipe(map(authDataBE => this.transformIntoAuthData(authDataBE)));
  }

  sendResetPasswordMail(email: string): Observable<boolean> {
    const url = `${environment.apiEnv}api/rest-auth/password/reset/`;
    return this.httpClient.post(url, { email }).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  resetPassword(uid: string, token: string, password: string, passwordConfirm: string): Observable<boolean> {
    const url = `${environment.apiEnv}api/rest-auth/password/reset/confirm/`;
    return this.httpClient.post(url, { uid, token, new_password1: password, new_password2: passwordConfirm }).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  checkToken(uid: string, token: string): Observable<boolean> {
    const url = `${environment.apiEnv}api/rest-auth/password/reset/check/`;
    return this.httpClient.post(url, { uid, token }).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  transformIntoAuthData(authDataBE: AuthDataBE): AuthData {
    return {
      brand: authDataBE.brand,
      language: authDataBE.preferred_language,
      redirectUrl: this.getRedirectUrl(authDataBE.redirect_url),
      role: authDataBE.is_staff ? UserRole.STAFF : UserRole.MEMBER,
      token: authDataBE.key,
    };
  }

  private getRedirectUrl(redirectUrlBE: string | null): string | null {
    const redirectUrlInGroup1Regex = /https:\/\/.+?(\/.+)/;
    const [, group1] = redirectUrlBE?.match(redirectUrlInGroup1Regex) ?? [];
    return group1 ?? null;
  }
}
