import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '@environment';
import { TranslationService } from '../../translation/translation.service';

@Injectable({
  providedIn: 'root',
})
// This service instances different objects from Google Maps, and makes sure the API is loaded
// This allows to do not have direct dependencies of Google Maps API in other services to be able unit test them
export class GoogleMapsService {
  private readonly API_KEY = environment.googleMapsApiKey;
  private readonly GOOGLE_API_LINK = 'https://www.google.com/maps/search/?api=1&query=';
  private loader: Loader;

  constructor(private translationService: TranslationService) {
    this.loader = this.getLoader();
  }

  createMapsUrlQuery(address: string): string {
    return encodeURI(`${this.GOOGLE_API_LINK}${address}`);
  }

  async getMapTypeId(): Promise<string> {
    const { MapTypeId } = await this.loader.importLibrary('maps');
    return MapTypeId.ROADMAP;
  }

  async getMap(mapElement: HTMLElement, mapOptions: google.maps.MapOptions): Promise<google.maps.Map> {
    const { Map } = await this.loader.importLibrary('maps');
    const map = new Map(mapElement, mapOptions);
    await this.waitForProjection(map);
    return map;
  }

  async getLatLng(lat: number, lng: number): Promise<google.maps.LatLng> {
    const { LatLng } = await this.loader.importLibrary('core');
    return new LatLng(lat, lng);
  }

  async getPoint(x: number, y: number): Promise<google.maps.Point> {
    const { Point } = await this.loader.importLibrary('core');
    return new Point(x, y);
  }

  async getSize(width: number, height: number): Promise<google.maps.Size> {
    const { Size } = await this.loader.importLibrary('core');
    return new Size(width, height);
  }

  async addMarker(markerOptions: google.maps.MarkerOptions): Promise<google.maps.Marker> {
    const { Marker } = await this.loader.importLibrary('marker');
    return new Marker(markerOptions);
  }

  async getLatLngBounds(): Promise<google.maps.LatLngBounds> {
    const { LatLngBounds } = await this.loader.importLibrary('core');
    return new LatLngBounds();
  }

  async addAutocomplete(inputElement: HTMLInputElement): Promise<google.maps.places.Autocomplete> {
    const { Autocomplete } = await this.loader.importLibrary('places');
    return new Autocomplete(inputElement);
  }

  async distanceToTopLeftInPx(latLng: google.maps.LatLng, map: google.maps.Map): Promise<google.maps.Point> {
    const { Point, LatLng } = await this.loader.importLibrary('core');
    const zoom = map.getZoom();
    if (zoom === undefined) {
      throw new Error('Map zoom level is undefined');
    }
    const scale = Math.pow(2, zoom);
    const bounds = map.getBounds();
    if (!bounds) {
      throw new Error('Map bounds are undefined');
    }
    const projection = map.getProjection();
    if (!projection) {
      throw new Error('Map projection is undefined');
    }
    const NorthWest = new LatLng(bounds.getNorthEast().lat(), bounds.getSouthWest().lng());
    const pointNorthWest = projection.fromLatLngToPoint(NorthWest);
    const pointLatLong = projection.fromLatLngToPoint(latLng);
    if (!pointNorthWest || !pointLatLong) {
      throw new Error('Projection points are null');
    }
    return new Point(
      Math.floor((pointLatLong.x - pointNorthWest.x) * scale),
      Math.floor((pointLatLong.y - pointNorthWest.y) * scale),
    );
  }

  async getCurrentMapRadius(map: google.maps.Map): Promise<number | null> {
    const bounds = map.getBounds();
    if (!bounds) return null;

    const { spherical } = await this.loader.importLibrary('geometry');
    const { LatLng } = await this.loader.importLibrary('core');

    const center = bounds.getCenter();
    const northSouthRadius = spherical.computeDistanceBetween(
      center,
      new LatLng(bounds.getNorthEast().lat(), center.lng()),
    );
    const eastWestRadius = spherical.computeDistanceBetween(
      center,
      new LatLng(center.lat(), bounds.getNorthEast().lng()),
    );
    const radius = northSouthRadius <= eastWestRadius ? northSouthRadius : eastWestRadius;

    return Number(radius.toFixed(0));
  }

  async getCircle(options: google.maps.CircleOptions): Promise<google.maps.Circle> {
    const { Circle } = await this.loader.importLibrary('maps');
    return new Circle(options);
  }

  async clearAllMapEvents(googleMapInstance: google.maps.Map): Promise<void> {
    const { event } = await this.loader.importLibrary('core');
    event.clearInstanceListeners(googleMapInstance);
  }

  async addSearchBox(inputElement: HTMLInputElement): Promise<google.maps.places.SearchBox> {
    const { SearchBox } = await this.loader.importLibrary('places');
    return new SearchBox(inputElement);
  }

  async geocode(latLng: google.maps.LatLng): Promise<google.maps.GeocoderResult[]> {
    const { Geocoder, GeocoderStatus } = await this.loader.importLibrary('geocoding');
    const geocoder = new Geocoder();
    try {
      return await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === GeocoderStatus.OK && results) {
            resolve(results);
          } else {
            reject([]);
          }
        });
      });
    } catch (error) {
      return [];
    }
  }

  private getLoader(): Loader {
    const language = this.translationService.getActiveLanguage();
    return new Loader({ apiKey: this.API_KEY, language });
  }

  private async waitForProjection(map: google.maps.Map): Promise<void> {
    const { event } = await this.loader.importLibrary('core');

    return new Promise<void>(resolve => {
      event.addListenerOnce(map, 'projection_changed', () => {
        resolve();
      });
    });
  }
}
