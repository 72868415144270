import { AuthData } from '../../models/auth-data.model';
import { BrandType } from '../../models/brand.model';
import { UserRole } from '../../models/user-role.model';

export const AUTH_DATA_STAFF: AuthData = {
  brand: BrandType.ESSENTIALIST,
  language: 'en',
  redirectUrl: null,
  role: UserRole.STAFF,
  token: '0123456789',
};

export const AUTH_DATA_ESS_MEMBER: AuthData = {
  brand: BrandType.ESSENTIALIST,
  language: 'es',
  redirectUrl: null,
  role: UserRole.MEMBER,
  token: '0123456789',
};

export const AUTH_DATA_AMEX_MEMBER: AuthData = {
  brand: BrandType.AMEX,
  language: 'en',
  redirectUrl: null,
  role: UserRole.MEMBER,
  token: '0123456789',
};
