import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ExperienceType } from '../models/experience-type.model';
import { TIME_REGEX_DAYS, TIME_REGEX_HOURS } from '../constants/time-regex';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private readonly ENGLISH_LANGUAGE = 'en';

  // expects a string that can match this two formats: dd HH:mm:ss or HH:mm:ss
  // In English
  // returns a formated value for the input in format dd h:mmA
  //In other languages
  // returns a formated value for the input in format dd HH:mm
  getFormattedTime(valueTime: string, durationFormat = false, language = this.ENGLISH_LANGUAGE): string | null {
    if (!valueTime) {
      return null;
    }

    const splitTime = valueTime.split(/ /);
    let days: string | null;
    let time: string;
    if (splitTime[0].includes(':')) {
      days = null;
      time = splitTime[0];
    } else {
      days = splitTime[0];
      time = splitTime[1];
    }

    let formattedTime = this.formatTime(time, durationFormat, language);

    if (days) {
      formattedTime = `${days} days ${formattedTime}`;
    }

    return formattedTime;
  }

  getFormattedDuration(time: string, format: 'acc' | 'simple' = 'simple'): string {
    if (format === 'acc') {
      return this.getFormattedDurationAcc(time);
    }
    return this.getFormattedDurationSimple(time);
  }

  private getFormattedDurationSimple(time: string): string {
    const timeElements = [];
    if (time.match(TIME_REGEX_DAYS)) {
      const aux = time.split(' ');
      if (+aux[0] > 0) timeElements.push(`${+aux[0] || '00'}d`);
      time = aux[1];
    }
    if (time.match(TIME_REGEX_HOURS)) {
      const aux = time.split(':');
      if (+aux[0] > 0) timeElements.push(`${+aux[0] || '00'}h`);
      if (+aux[1] > 0) timeElements.push(`${+aux[1] || '00'}min`);
    }
    return timeElements.join(' ');
  }

  private getFormattedDurationAcc(time: string): string {
    const timeElements = [];
    let hoursFromDays = 0;
    if (time.match(TIME_REGEX_DAYS)) {
      const aux = time.split(' ');
      hoursFromDays = +aux[0] * 24;
      time = aux[1];
    }
    if (time.match(TIME_REGEX_HOURS)) {
      const aux = time.split(':');
      hoursFromDays += +aux[0];
      timeElements.push(`${hoursFromDays || '00'}h`);
      timeElements.push(`${+aux[1] || '00'}min`);
    }
    return timeElements.join(' ');
  }

  // from 2021-04-21T16:17:00 to 16:17 (no localtime)
  getTimeFromDateTime(date: string): string | null {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (!date || !dateRegex.test(date)) {
      return null;
    }
    const noDate = date.split('T');
    const arrivalTiming = noDate[1].split(':');
    return `${arrivalTiming[0]}:${arrivalTiming[1]}`;
  }

  // from HH:mm:ss to h:mm a
  getShortTime(timeString: string | null): string | null {
    const regexHours = '^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])$';
    if (!timeString || !timeString.match(regexHours)) {
      return null;
    }
    const [hours, minutes] = timeString.split(':');
    const date = new Date();
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return new DatePipe('en-US').transform(date, 'shortTime');
  }

  // from "13:00:00" to "13:00"
  getTime(fromTime: string): string | null {
    let hours = null;
    let minutes = null;
    let time = null;
    if (fromTime) {
      hours = fromTime.split(':')[0];
      minutes = fromTime.split(':')[1];
      time = `${hours}:${minutes}`;
    }
    return time;
  }

  cropDescription(description: string, maxLength = 500): string {
    const tagIndex = description.indexOf('>');
    description = description.slice(tagIndex + 1);
    if (description.length <= maxLength) {
      return description;
    }
    description = description.slice(0, maxLength);
    let dotIndex = description.lastIndexOf('.');
    dotIndex = dotIndex === -1 ? description.length - 1 : dotIndex;
    description = `${description.slice(0, dotIndex + 1)}`;
    return description;
  }

  getOrdinalSuffix(dateString: string | null | undefined): string {
    const dateRegex = /\d{4}-\d{2}-(\d{2})/;
    const dayString = dateString?.match(dateRegex)?.[1];
    if (dayString) {
      const day = parseInt(dayString);

      if (day > 3 && day < 21) {
        return 'th';
      }

      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }
    return '';
  }

  buildPublicLink(slug: string, typeSlug: string, domain: string): string {
    let type;
    switch (typeSlug) {
      case ExperienceType.ACCOMMODATION:
        type = 'hotels';
        break;
      case ExperienceType.EAT_DRINK:
        type = 'restaurants';
        break;
      case ExperienceType.EXPERIENCE:
        type = 'experiences';
        break;
      case ExperienceType.PLACES_INTEREST:
        type = 'places';
        break;
      case ExperienceType.TRANSPORTATION:
        type = 'transportations';
        break;
    }
    return `${domain}${type}/${slug}`;
  }

  buildBookingExperienceLink(entity: { id: number | null; booking_hash?: string }, domain: string): string {
    return `${domain}trip/${entity.booking_hash}/experience/${entity.id}`;
  }

  createIntersectionObserver(
    callback: IntersectionObserverCallback,
    target: HTMLElement,
    options?: IntersectionObserverInit,
  ): IntersectionObserver | null {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(callback, options);
      observer.observe(target);
      return observer;
    }
    return null;
  }

  // NFD transforms accent letter into Latin letter + diacritic symbol (á -> a'), /\p{Diacritic}/gu removes diacritic symbols (a' -> a)
  normalizeString(value: string): string {
    return value.normalize('NFD').replace(/\p{Diacritic}/gu, '');
  }

  includesNormalized(value1: string, value2: string): boolean {
    return this.normalizeString(value1).toLowerCase().includes(this.normalizeString(value2).toLowerCase());
  }

  private formatTime(time: string, durationFormat = false, language: string): string | null {
    const hours = Number(time.split(':')[0]);
    const minutes = Number(time.split(':')[1]);
    let formattedTime = '';
    const hoursMap: Record<number, number> = {
      12: 12,
      13: 1,
      14: 2,
      15: 3,
      16: 4,
      17: 5,
      18: 6,
      19: 7,
      20: 8,
      21: 9,
      22: 10,
      23: 11,
      24: 0,
    };

    if (durationFormat) {
      if (!hours && !minutes) {
        return null;
      }

      if (hours) {
        formattedTime += `${hours}h`;
      }

      formattedTime += hours ? `, ${minutes}min` : `${minutes}min`;
    } else if (language !== this.ENGLISH_LANGUAGE) {
      const [hour, minute] = time.split(':');
      formattedTime = `${hour}:${minute}`;
    } else {
      if (!hours && !minutes) {
        formattedTime = `12:00 am`;
      } else {
        const literalMinutes = time.split(':')[1].length === 1 ? `0${time.split(':')[1]}` : time.split(':')[1];
        if (hours < 12) {
          formattedTime += `${time.split(':')[0]}:${literalMinutes} am`;
        } else {
          formattedTime += `${hoursMap[hours]}:${literalMinutes} pm`;
        }
      }
    }

    return formattedTime;
  }
}
