import { AuthDataBE } from '../../models/auth-data-be.model';
import { BrandType } from '../../models/brand.model';

export const AUTH_DATA_STAFF_BE: AuthDataBE = {
  brand: BrandType.ESSENTIALIST,
  is_staff: true,
  is_superuser: true,
  key: '0123456789',
  preferred_language: 'en',
  redirect_url: null,
  token: '0123456789',
};

export const AUTH_DATA_ESS_MEMBER_BE: AuthDataBE = {
  brand: BrandType.ESSENTIALIST,
  is_staff: false,
  is_superuser: false,
  key: '0123456789',
  preferred_language: 'es',
  redirect_url: null,
  token: '0123456789',
};

export const AUTH_DATA_AMEX_MEMBER_BE: AuthDataBE = {
  brand: BrandType.AMEX,
  is_staff: false,
  is_superuser: false,
  key: '0123456789',
  preferred_language: 'en',
  redirect_url: null,
  token: '0123456789',
};
