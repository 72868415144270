import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuthData } from '../../models/auth-data.model';
import { UserRole } from '../../models/user-role.model';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private readonly TOKEN_KEY = 'ess_token';
  private readonly ROLE_KEY = 'ess_role';
  private readonly LANGUAGE_KEY = 'ess_language';
  private readonly BRAND_KEY = 'ess_brand';

  clearAuthData(): void {
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.removeItem(this.ROLE_KEY);
    localStorage.removeItem(this.LANGUAGE_KEY);
    localStorage.removeItem(this.BRAND_KEY);
    sessionStorage.removeItem(this.TOKEN_KEY);
    sessionStorage.removeItem(this.ROLE_KEY);
    sessionStorage.removeItem(this.LANGUAGE_KEY);
    sessionStorage.removeItem(this.BRAND_KEY);
  }

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY) || sessionStorage.getItem(this.TOKEN_KEY);
  }

  getUserRole(): UserRole | null {
    return (localStorage.getItem(this.ROLE_KEY) as UserRole) || (sessionStorage.getItem(this.ROLE_KEY) as UserRole);
  }

  getLanguage(): string | null {
    return localStorage.getItem(this.LANGUAGE_KEY) || sessionStorage.getItem(this.LANGUAGE_KEY);
  }

  getBrand(): string | null {
    return localStorage.getItem(this.BRAND_KEY) || sessionStorage.getItem(this.BRAND_KEY);
  }

  moveCookieToken(): void {
    const token = this.getCookieToken();
    if (token) {
      this.removeCookieToken();
      localStorage.setItem(this.TOKEN_KEY, token);
      // if token was set to cookie by BE, user is staff
      localStorage.setItem(this.ROLE_KEY, UserRole.STAFF);
      // if token was set to cookie by BE, language is en
      localStorage.setItem(this.LANGUAGE_KEY, 'en');
    }
  }

  saveAuthData(authData: AuthData, keepData: boolean): void {
    this.clearAuthData();
    const storage = keepData ? localStorage : sessionStorage;
    storage.setItem(this.TOKEN_KEY, authData.token);
    storage.setItem(this.ROLE_KEY, authData.role || '');
    storage.setItem(this.LANGUAGE_KEY, authData.language);
    storage.setItem(this.BRAND_KEY, authData.brand);
  }

  private getCookieToken(): string | null {
    return (
      document.cookie
        .split(';')
        .find(el => {
          const [key] = el.split('=');
          return key.trim() === this.TOKEN_KEY;
        })
        ?.split('=')[1] || null
    );
  }

  private removeCookieToken(): void {
    document.cookie = `${this.TOKEN_KEY}=;path=/;domain=${environment.cookiesDomain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}
